import { setActiveAccountAction } from './appSettings.action';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { actionsTypes } from './constants';
import jwt from 'jsonwebtoken';

// import ENV_CONFIG from 'src/constants/Config';
// import { getActiveAccount, getUserLanguage } from 'src/components/AppSettings/appSettings.selector';

export function* setActiveAccountData({ payload }) {
  const { access_token } = payload;
  const decodedJwt = yield call([jwt, 'decode'], access_token);
  if (decodedJwt === null) {
    yield put(setActiveAccountAction.error());
  }
  if (decodedJwt.exp * 1000 < Date.now()) {
    yield put(setActiveAccountAction.error());
  }

  const tenantId = decodedJwt.scope.replace('tenant/', '');
  let accountId = decodedJwt.sub;
  // accountId = accountId.replace(/%3D/g, '=');

  yield put(
    setActiveAccountAction.success({
      access_token: payload.access_token,
      accountId,
      tenantId,
      endpoint: decodedJwt.endpoint,
      data: decodedJwt,
    }),
  );
}

export default function* appSeetingsSagas() {
  yield all([takeLatest(actionsTypes.ACTIVE_ACCOUNT.PENDING, setActiveAccountData)]);
}
