import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const appSettings = state => state.get('appSettings', new Map());

export const getStatusData = createSelector(appSettings, data => data.get('status') || '');
export const getAccountId = createSelector(appSettings, data => data.get('accountId') || '');
export const getTenantId = createSelector(appSettings, data => data.get('tenantId') || '');
export const getAccessToken = createSelector(appSettings, data => data.get('access_token') || '');
export const getEndpoint = createSelector(appSettings, data => data.get('endpoint') || '');
export const getRedirect = createSelector(appSettings, data => data.get('redirect') || '');
export const getRedirectError = createSelector(
  appSettings,
  data => data.get('redirectError') || '',
);
