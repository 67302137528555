import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';
import store from './store/store';
import i18n from './i18n';
import Notifications from './components/Notification/Notifications.container';

React.icons = icons;
const getLibrary = provider => new Web3(provider);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Notifications />
        <App />
      </Web3ReactProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
