import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import appSettingsReducer from 'src/components/AppSettings/appSettings.reducer';
import walletReducer from 'src/views/Wallet/wallet.reducer';
import notifications from 'src/components/Notification/reducer';
import { actionsTypes } from 'src/components/AppSettings/constants';

const createRootReducer = history => {
  const appReducer = combineReducers({
    appSettings: appSettingsReducer,
    wallet: walletReducer,
    router: connectRouter(history),
    notifications,
  });

  return (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === actionsTypes.RESET_APP_SETTING) {
      state = undefined;
    }

    return appReducer(state, action);
  };
};

export default createRootReducer;
