import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
  language: localStorage.getItem('i18nextLng'),
});

const appSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.ACTIVE_ACCOUNT.PENDING:
      return state
        .set('status', STATUS.LOADING)
        .set('redirect', payload.redirect || state.get('redirect'))
        .set('redirectError', payload.redirectError || state.get('redirectError'));
    case actionsTypes.ACTIVE_ACCOUNT.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('data', payload.data)
        .set('access_token', payload.access_token)
        .set('endpoint', payload.endpoint)
        .set('tenantId', payload.tenantId)
        .set('accountId', payload.accountId);
    case actionsTypes.ACTIVE_ACCOUNT.ERROR:
      return state.set('status', STATUS.ERROR);
    default:
      return state;
  }
};
export default appSettingsReducer;
