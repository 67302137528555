import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import ImmutablePropsType from 'react-immutable-proptypes';
import NotificationSystem from 'react-notification-system';

const Notifications = props => {
  const { notifications, hideNotification } = props;
  const notificationSystemRef = useRef(null);

  useEffect(() => {
    handleReceivedNotifications(notifications);
  });

  const handleReceivedNotifications = notifications => {
    notifications.map(addNotification);
  };

  const addNotification = notification => {
    const uid = notification.get('id');
    const notificationBody = {
      uid,
      title: <Translation>{t => t(notification.get('title'))}</Translation>,
      message: <Translation>{t => t(notification.get('message'))}</Translation>,
      level: notification.get('notificationType'),
      dismissible: notification.get('dismissible'),
      action: notification.get('action'),
      onRemove: () => hideNotification(uid),
    };

    const autoDismiss = notification.get('autoDismiss');
    if (!isNaN(autoDismiss)) {
      notificationBody.autoDismiss = autoDismiss;
    }
    return notificationSystemRef.current.addNotification(notificationBody);
  };

  return <NotificationSystem ref={notificationSystemRef} />;
};

export default Notifications;

Notifications.propTypes = {
  notifications: ImmutablePropsType.list.isRequired,
  hideNotification: PropTypes.func.isRequired,
};
