import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
});

const appSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.ADD_WALLET.PENDING:
      return state.set('status', STATUS.ADDING);
    case actionsTypes.ADD_WALLET.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('data', payload.data);
    case actionsTypes.ADD_WALLET.ERROR:
      return state.set('status', STATUS.ERROR);
    case actionsTypes.ADD_CUSTOM_WALLET.PENDING:
      return state.set('status', STATUS.ADDING);
    case actionsTypes.ADD_CUSTOM_WALLET.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('data', payload.data);
    case actionsTypes.ADD_CUSTOM_WALLET.ERROR:
      return state.set('status', STATUS.ERROR);
    case actionsTypes.CHECK_WALLET_SIGNATURE.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.CHECK_WALLET_SIGNATURE.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('data', payload.data);
    case actionsTypes.CHECK_WALLET_SIGNATURE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CLEAR_VALUES:
      return initialState;

    default:
      return state;
  }
};
export default appSettingsReducer;
