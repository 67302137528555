import { fromJS, Map } from 'immutable';
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from 'src/constants/Actions';

const initialState = fromJS([]);

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return state.push(new Map(action));
    case HIDE_NOTIFICATION:
      return state.filter(item => item.get('id') !== action.id);
    default:
      return state;
  }
};
export default NotificationsReducer;
