import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { actionsTypes } from './constants';
import axios from 'axios';
import {
  getAccessToken,
  getAccountId,
  getEndpoint,
  getTenantId,
} from 'src/components/AppSettings/appSettings.selector';
import { addCustomWalletAction, addWalletAction, checkSignatureAction } from './wallet.action';
import { showError, showSuccess } from 'src/components/Notification/actions';

// import ENV_CONFIG from 'src/constants/Config';
// import { getActiveAccount, getUserLanguage } from 'src/components/AppSettings/appSettings.selector';

export function* addWalletData({ payload }) {
  const tenantId = yield select(getTenantId);
  const accountId = yield select(getAccountId);
  const access_token = yield select(getAccessToken);
  const endpoint = yield select(getEndpoint);
  try {
    const response = yield call(
      [axios, 'post'],
      `${endpoint}/tenants/${tenantId}/accounts/${accountId}/wallets/metamask`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      },
    );

    yield put(
      addWalletAction.success({
        data: response.data,
      }),
    );
  } catch (e) {
    let message = typeof e.response !== 'undefined' ? e.response.data.message : e.message;
    yield put(addWalletAction.error());
    if (message === 'Wallet does already exist') {
      yield put(
        showError({
          title: 'view_wallets:Notification.AddMetamask.Title',
          message: `view_wallets:Notification.AddMetamask.WalletExists`,
        }),
      );
    }
  }
}

export function* addCustomWalletData({ payload }) {
  const tenantId = yield select(getTenantId);
  const accountId = yield select(getAccountId);
  const access_token = yield select(getAccessToken);
  const endpoint = yield select(getEndpoint);

  try {
    const response = yield call(
      [axios, 'post'],
      `${endpoint}/tenants/${tenantId}/accounts/${accountId}/wallets/custom`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      },
    );
    yield put(
      addCustomWalletAction.success({
        data: response.data,
      }),
    );
    yield put(
      showSuccess({
        title: 'view_wallets:Notification.AddCustomWalletSuccess.Title',
        message: `view_wallets:Notification.AddCustomWalletSuccess.Message`,
      }),
    );
  } catch (e) {
    yield put(addCustomWalletAction.error());
  }
}

export function* checkSignatureData({ payload }) {
  const tenantId = yield select(getTenantId);
  const accountId = yield select(getAccountId);
  const access_token = yield select(getAccessToken);
  const endpoint = yield select(getEndpoint);

  const walletId = payload.walletId;

  delete payload.walletId;

  try {
    const response = yield call(
      [axios, 'put'],
      `${endpoint}/tenants/${tenantId}/accounts/${accountId}/wallets/${walletId}/metamask`,
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      },
    );

    yield put(
      checkSignatureAction.success({
        data: response.data,
      }),
    );
    yield put(
      showSuccess({
        title: 'view_wallets:Notification.AddCustomWalletSuccess.Title',
        message: `view_wallets:Notification.AddCustomWalletSuccess.Message`,
      }),
    );
  } catch (e) {
    yield put(checkSignatureAction.error());
  }
}

export default function* walletsSagas() {
  yield all([takeLatest(actionsTypes.ADD_WALLET.PENDING, addWalletData)]);
  yield all([takeLatest(actionsTypes.ADD_CUSTOM_WALLET.PENDING, addCustomWalletData)]);
  yield all([takeLatest(actionsTypes.CHECK_WALLET_SIGNATURE.PENDING, checkSignatureData)]);
}
