import { actionsTypes } from './constants';

export const setActiveAccountAction = {
  pending: payload => ({
    type: actionsTypes.ACTIVE_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ data, tenantId, accountId, access_token, endpoint }) => ({
    type: actionsTypes.ACTIVE_ACCOUNT.SUCCESS,
    payload: { data, tenantId, accountId, access_token, endpoint },
  }),
  error: () => ({
    type: actionsTypes.ACTIVE_ACCOUNT.ERROR,
  }),
};
