import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'WALLET';

const ADD_WALLET = asyncActionType(`${NAMESPACE} ADD`);
const ADD_CUSTOM_WALLET = asyncActionType(`${NAMESPACE} ADD CUSTOM`);
const CHECK_WALLET_SIGNATURE = asyncActionType(`${NAMESPACE} CHECK SIGNATURE`);

const CLEAR_VALUES = `CLEAR_VALUES`;

export const actionsTypes = {
  ADD_WALLET,
  ADD_CUSTOM_WALLET,
  CHECK_WALLET_SIGNATURE,
  CLEAR_VALUES,
};
