import { actionsTypes } from './constants';

export const addWalletAction = {
  pending: payload => ({
    type: actionsTypes.ADD_WALLET.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.ADD_WALLET.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.ADD_WALLET.ERROR,
  }),
};

export const addCustomWalletAction = {
  pending: payload => ({
    type: actionsTypes.ADD_CUSTOM_WALLET.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.ADD_CUSTOM_WALLET.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.ADD_CUSTOM_WALLET.ERROR,
  }),
};

export const checkSignatureAction = {
  pending: payload => ({
    type: actionsTypes.CHECK_WALLET_SIGNATURE.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.CHECK_WALLET_SIGNATURE.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.CHECK_WALLET_SIGNATURE.ERROR,
  }),
};

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
