import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';

const detectionOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookie', 'navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  // cookieMinutes: 10,
  // cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: 'en',
    load: 'currentOnly',
    detection: detectionOptions,
    fallbackLng: 'en', // use en if detected lng is not available
    supportedLngs: ['en', 'de'],

    ns: ['validation', 'common', 'glossary', 'countries'],

    debug: false,
    keySeparator: '.', // we do not use keys in form messages.welcome
    backend: {
      loadPath: () => {
        const { origin } = window.location;
        return `${origin}/locales/{{lng}}/{{ns}}.json`;
      },
      crossDomain: true,
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, rawFormat, lng) => {
        if (value instanceof Date) return moment(value).format(rawFormat);

        const [format, ...additionalValues] = rawFormat.split(',').map(v => v.trim());
        switch (format) {
          case 'uppercase':
            return value.toUpperCase();
          case 'price':
            return Intl.NumberFormat(lng, {
              style: 'currency',
              currency: additionalValues[0],
              maximumFractionDigits: 0,
            }).format(value);
          case 'number':
            return Intl.NumberFormat(lng, {
              maximumFractionDigits: 5,
            }).format(value);
          case 'money':
            return Intl.NumberFormat(lng, {
              maximumFractionDigits: 2,
            }).format(value);
          case 'crypto':
            return Intl.NumberFormat(lng, {
              maximumFractionDigits: 8,
            }).format(value);
          case 'float':
            return Intl.NumberFormat(lng, {
              maximumFractionDigits: 2,
            }).format(value);
          default:
            return value;
        }
      },
    },

    // react-i18next options
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
